import { Component, ViewChild, AfterViewInit, Input, NgModule } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import {CommonModule} from '@angular/common';
import { Tenant } from '../tenants/tenants.component';
import { MatSlideToggleChange, MatSlideToggleModule} from '@angular/material/slide-toggle';


const TENANT_DATA = [
  {solution_id: '1', sku: 'SKU1', name: 'Solution 1', assigned: true}
 

];

@Component({
  selector: 'app-tenants-using-resource',
  standalone: true,
  templateUrl: './tenants-using-resource.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, MatSlideToggleModule,CommonModule, RouterModule],
  styleUrls: ['./tenants-using-resource.component.css']
})
export class TenantsUsingResourceComponent {
  displayedColumns: string[] = ['name','assigned'];
  public token = sessionStorage.getItem("token") as string;
  @Input() dataSource = new MatTableDataSource(TENANT_DATA);
  @Input() sku: string = "";
        
    ngOnChanges(changes: any) {
        
        this.getSolutions(changes.sku.currentValue);
        // You can also use categoryId.previousValue and 
        // categoryId.firstChange for comparing old and new values
        
    }
  public selectedRow:String = '1';
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, private router: Router){
  }



  @ViewChild(MatSort) sort!: MatSort
  ngAfterViewInit(): void {
  this.dataSource.sort = this.sort;
  }

  setSelected(row: Tenant):void {
    this.selectedRow = row.tenantId;
    // console.log(this.selectedRow);
  }

  navigateToDetails(tenantId:String):void {
    // console.log(` Nav to Tenant ID: ${tenantId}`);
    this.router.navigate(['/tenant-detail'], { queryParams: { tenantId: tenantId } });
  }
  
  async getSolutions(sku:string){
    this.apiService.getSolutionsBySku(sku).subscribe((data: any) => {
    this.dataSource = new MatTableDataSource(data);
   })
  }

  async changeStatus(event: MatSlideToggleChange, element: any) {
    let inputObject: any = {}
    inputObject.skuId=element.sku
    inputObject.solution_id = element.solution_id
    inputObject.enabled = event.checked
    // console.log(inputObject)
    this.apiService.addOrRemoveSolution(inputObject).subscribe((data: any) => {
     
    });
  }
}
