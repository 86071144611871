import { Component, Input, OnInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {PieGraphComponent} from '../pie-graph/pie-graph.component';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { VerticalProgressBarComponent } from '../vertical-progress-bar/vertical-progress-bar.component';

import {CommonModule} from '@angular/common';
import {LineGraphComponent} from '../line-graph/line-graph.component';

@Component({
  selector: 'app-usage-card',
  standalone: true,
  templateUrl: './usage-card.component.html',
  imports: [MatCardModule, PieGraphComponent, MatSlideToggleModule, VerticalProgressBarComponent, LineGraphComponent, CommonModule],
  styleUrls: ['./usage-card.component.css']
})
export class UsageCardComponent implements OnInit{

  @Input() public entitlement: any;
  toggleState = false;

  public quantity = 0;
  public percentage = 0;

  public lineValues = [0,0,0,0,0,0,0,0,0,0,0,0,0];

  public usage = 0

  toggleChart() {
   this.toggleState = !this.toggleState;
    // console.log("toggleChart called");
  }

  ngOnInit(){
    console.log("entitlement", this.entitlement);
    this.usage = this.entitlement.usage.instance_count;
    this.percentage = (this.usage / this.entitlement.usage.capacity) * 100
    this.quantity = this.usage
    this.entitlement.usage.forEach((usage: any) => {
      let month = new Date(usage.add_date).getMonth()
      // console.log(`month: ${month}`)
      this.lineValues[month] = usage.QTY
      
    });
    // this.barColor = this.getBackgroundColor();
    // console.log("lineValues", this.lineValues)
  }

}
