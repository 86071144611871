<mat-card class="card circle-graph">
  <mat-card-title>
    <div class="card-header"> 
      <div class=" title-line mt-5">
        <div class="title-container">
          <div class="icon">
            <img src="../../assets/usage-chart.png" alt="usage-chart-icon" />
          </div>
          <p class="card-title">{{entitlement?.sku}}</p>  
        </div>
        <div class="title-conainer">
          <p class="card-title mr-20">QTY: {{entitlement?.amount}} </p>
        </div>
       </div> <!-- title line end -->
       <div class="slide-toggle">
        <p class="toggle-title month">Month</p>
        <div class="toggle">
          <mat-slide-toggle [checked]="false" [hideIcon]="true" (toggleChange)="toggleChart()" />
        </div>
        <p class="toggle-title year">Year</p>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content class="card-content">
    <ng-container *ngIf="this.toggleState === false">
      <div class="graphs">
        <app-pie-graph [entitlement]="entitlement" title={{entitlement?.solution}} [fontSize]="'30'" [subtitle]="'Usage'"
          [radius]="72.5" [showSubtitle]="true"
          [subtitleFontSize]="'15'"></app-pie-graph>
        <app-vertical-progress-bar [percentage]="percentage" color="color" />
      </div>
    </ng-container>
    <ng-container *ngIf="this.toggleState === true">
      <div class="graphs">
        <app-line-graph [values]="lineValues" />
      </div>
    </ng-container>
    <div class="information">
    <ng-container *ngIf="usage">
      <p class="topTxt"><span class="boldtxt">Usage:</span> {{(usage)}} - {{entitlement?.solution}}</p>
    </ng-container>
    <ng-container *ngIf="!usage">
      <p class="topTxt"><span class="boldtxt">Usage:</span> 0 - {{entitlement?.solution}}</p>
    </ng-container>

      <p class="botTxt"><span class="boldtxt">Cost:</span> $5.50/VM</p>

    </div>
  </mat-card-content>
</mat-card>