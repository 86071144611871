<div class="wrapper">
  <div style="width: 500px;">
    {{this.sku}}
    <div class="accordian-container">
      <div class="btn-container">
        <button (click)="navigateToDetails(selectedRow)" mat-flat-button color="primary"
          class="primary-btn mb-15">
          View Tenant
        </button>
      </div>
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- TenantId Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Solution Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
       <ng-container matColumnDef="assigned">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
          <td mat-cell *matCellDef="let element">
              <mat-slide-toggle class="mat-accent" (change)="changeStatus($event,element)" [checked]=" element.assigned" [labelPosition]="'after'"
                [hideIcon]="true">
                <p class='label'>Enabled</p>
              </mat-slide-toggle>
        </ng-container>


        <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setSelected(row)"
          [ngClass]="{selecetedRow: selectedRow===row.tenantId}">
        </tr>
      </table>
    </div>
  </div>
</div>