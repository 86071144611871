<mat-card class="card circle-graph">
  <mat-card-title>
    <div class="card-header">
      <div class="slide-toggle">
        <p class="toggle-title month">Month</p>
        <div class="toggle">
          <mat-slide-toggle [checked]="false" [hideIcon]="true" (toggleChange)="toggleChart()" />
        </div>
        <p class="toggle-title year">Year</p>
      </div>
      <div class=" title-line">
        <div class="icon">
          <img src="../../assets/usage-chart.png" alt="usage-chart-icon" />
        </div>
        <p class="card-title">{{entitlement.sku}}</p>
        <p class="qty">QTY: {{entitlement.amount}} </p>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content class="card-content">
    <ng-container *ngIf="this.toggleState === false">
      <div class="graphs">
        <app-pie-graph [top3Usage]="entitlement" title={{entitlement.solution}} [fontSize]="'40'" [subtitle]="'Usage'"
          [radius]="72.5" [showSubtitle]="true"
          [subtitleFontSize]="'20'"></app-pie-graph>
        <app-vertical-progress-bar [percentage]="percentage" color="color" />
      </div>
    </ng-container>
    <ng-container *ngIf="this.toggleState === true">
      <div class="graphs">
        <app-line-graph [values]="lineValues" />
      </div>
    </ng-container>
    <div class="information">

      <p class="topTxt"><span class="boldtxt">Usage:</span> {{usage}} - {{entitlement.solution}}</p>

      <p class="botTxt"><span class="boldtxt">Cost:</span> $5.50/VM</p>

    </div>
  </mat-card-content>
</mat-card>