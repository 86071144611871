<div class="inner-container">
    <div class="header">
      <div class="title-wrapper">
        <div class="icon"><img width="35" height="35" src="../../assets/entitlement-icon.png" />
        <p class="table-title">Utility Contract</p></div>
      </div>
      <div class="button-wrapper">

        <button (click)="removeEntitlement(selectedRow)" mat-button color="primary"
          class="secondary-btn mr-20">Remove Entitlement</button>
        <button (click)="addEntitlement()" mat-flat-button color="primary" class="primary-btn">
          Add Entitlement
        </button>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Resource Name Column -->
      <ng-container matColumnDef="Entitlement Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Entitlement Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <!-- Start Date Column -->
      <ng-container matColumnDef="Start Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Date </th>
        <td mat-cell *matCellDef="let element"> {{element.startDate}} </td>
      </ng-container>

      <!-- End Date Column -->
      <ng-container matColumnDef="End Date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> End Date </th>
        <td mat-cell *matCellDef="let element"> {{element.endDate}} </td>
      </ng-container>

      <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setSelected(row)"
        [ngClass]="{selecetedRow: selectedRow===row.sk}">
    </table>

      <!-- <table mat-table [dataSource]="dataSource" matSort>
         Resource Name Column 
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SKUs </th>
          <td mat-cell *matCellDef="let element"> {{element.sku}} </td>
        </ng-container>
  
         Category Column 
        <ng-container matColumnDef="contractedAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Contracted Amount </th>
          <td mat-cell *matCellDef="let element"> {{element.contractedAmount}} </td>
        </ng-container>
  
         Details Column 
        <ng-container matColumnDef="contractedPrice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Contracted Price </th>
          <td mat-cell *matCellDef="let element"> {{element.contractedPrice}} </td>
        </ng-container>
        <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setSelected(row)"
          [ngClass]="{selecetedRow: selectedRow===row.skuId}">
      </table> -->
</div>