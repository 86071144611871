import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import {UsageCardComponent} from '../usage-card/usage-card.component';
import {BarGraphComponent} from 'dsio-ui-library';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';

export interface Billing{
  sku: String,
  qty: Number,
  rate: Number,
  extCost: Number,
}

export interface TennantResource{
  resourceId: String,
  resource: String,
  category: String,
  details: String,
}


@Component({
  selector: 'app-tenant-billing',
  standalone: true,
  templateUrl: './tenant-billing.component.html',
    imports: [MatButtonModule, MatTableModule, MatSortModule, MatCardModule, UsageCardComponent, BarGraphComponent, MatInputModule, MatFormFieldModule, MatSelectModule],
  styleUrls: ['./tenant-billing.component.css']
})
export class TenantBillingComponent implements AfterViewInit, OnInit {
  resources$!: Observable<any> 
  public RESOURCE_DATA: any[] = [];

  public selectedTenant:any;

  public usageData = {
  "sku": "SKU1",
  "amount": "5",
  "solution": "Solution 1",
  "usage": [
      {
          "sku_id": "SKU1",
          "QTY": "3",
          "tenant_id": "aa9f79d6-ca2b-4ffe-836e-a175f6f54ba6",
          "SK": "SKU1#2024-02-27T12:09:00",
          "PK": "usage#aa9f79d6-ca2b-4ffe-836e-a175f6f54ba6",
          "add_date": "2024-02-27T12:09:00",
          "record_type": "usage"
      },
  ]
};
  displayedColumns: string[] = ['resource', 'category'];
  dataSource = new MatTableDataSource(this.RESOURCE_DATA);
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, public route: ActivatedRoute){
  }

  @ViewChild(MatSort) sort!: MatSort


  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.selectedTenant = params['tenantId'];
      }
    );
    this.handleGetTenantResources()
  }
  

  ngAfterViewInit(): void {
  this.dataSource.sort = this.sort;
  }

  // async handleGetTennantBillings() {
  //     this.billings$ = this.apiService.get_tenant_billing(await this.keycloak.getToken());
  //     await this.billings$.subscribe({
  //       next(response) {
  //         response.forEach((billing:Billing) => {
  //           alert(JSON.stringify(billing));
  //         });
        
  //       },
  //       error(err) {
  //         console.log(err)
  //       },
  //     })
  // }

  async handleGetTenantResources() {
    this.resources$ = this.apiService.get_tenant_resources(this.selectedTenant);
    await this.resources$.subscribe((data: any) => {
      data.forEach((resource:any) => {
        let newResource = {
          resourceId: `${resource.PK}#${resource.SK}`,
          resource: resource._component_name,
          category: resource._resource_type,
          status: resource.status
        }
        this.RESOURCE_DATA.push(newResource);
      });
      this.dataSource.data = this.RESOURCE_DATA;
    })
}

}
