<div class="inner-container">
  <div class="filter-row">
    <mat-form-field appearance="outline" class="filter-select density-settings-3">
      <mat-label class="input-label">Resource</mat-label>
      <mat-select>
        <mat-option value="one">First option</mat-option>
        <mat-option value="two">Second option</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="filter-select density-settings-3">
      <mat-label class="input-label">Category</mat-label>
      <mat-select>
        <mat-option value="one">First option</mat-option>
        <mat-option value="two">Second option</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="filter-select density-settings-3">
      <mat-label class="input-label">Component</mat-label>
      <mat-select>
        <mat-option value="one">First option</mat-option>
        <mat-option value="two">Second option</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="filter-select density-settings-3">
      <mat-label class="input-label">Status</mat-label>
      <mat-select>
        <mat-option value="one">First option</mat-option>
        <mat-option value="two">Second option</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="filter-input density-settings-search-3">
      <mat-label class="input-label">Search</mat-label>
      <input matInput>
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Resource Name Column -->
        <ng-container matColumnDef="resource">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.resource}} </td>
        </ng-container>
  
        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
          <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>
  
        <!-- Details Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Details </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
  
        <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
  
</div>