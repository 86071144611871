import { Component, Input, OnInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {PieGraphComponent} from 'dsio-ui-library';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { VerticalProgressBarComponent } from 'dsio-ui-library';

import {CommonModule} from '@angular/common';
import {LineGraphComponent} from 'dsio-ui-library';

@Component({
  selector: 'app-usage-card',
  standalone: true,
  templateUrl: './usage-card.component.html',
  imports: [MatCardModule,PieGraphComponent, MatSlideToggleModule, VerticalProgressBarComponent, LineGraphComponent ,CommonModule],
  styleUrls: ['./usage-card.component.css']
})
export class UsageCardComponent implements OnInit{

  @Input() public entitlement: any;
  toggleState = false;

  public quantity = 0;
  public percentage = 0;

  public lineValues = [0,0,0,0,0,0,0,0,0,0,0,0,0];

  public usage = 0

  toggleChart() {
   this.toggleState = !this.toggleState;
  }

  ngOnInit(){
      this.usage = this.entitlement?.usage[0]?.QTY;
      this.percentage = (this.entitlement?.usage[0]?.QTY / this.entitlement?.amount) * 100
      this.quantity = this.entitlement?.amount
      this.entitlement?.usage.forEach((usageData: any) => {
      let month = new Date(usageData.add_date).getMonth()
      this.lineValues[month] = usageData.QTY
      
    });
    // this.barColor = this.getBackgroundColor()
  }

}