import { Component, ViewChild, AfterViewInit,OnInit  } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable, filter} from 'rxjs';
import {Resource} from '../resources/resources.component';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { Router, ActivatedRoute } from '@angular/router';

export interface TennantResource{
  resourceId: String,
  resource: String,
  category: String,
  details: String,
}


@Component({
  selector: 'app-tenant-resources',
  standalone: true,
  templateUrl: './tenant-resources.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule],
  styleUrls: ['./tenant-resources.component.css']
})
export class TenantResourcesComponent implements AfterViewInit, OnInit {

  resources$!: Observable<Array<TennantResource>|any> 

  RESOURCE_DATA: any[] = [
  ];
  displayedColumns: string[] = ['resource', 'category', 'status'];
  dataSource = new MatTableDataSource(this.RESOURCE_DATA);
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService , public route: ActivatedRoute){
  }

  @ViewChild(MatSort) sort!: MatSort

  public selectedTenant:any;
  ngAfterViewInit(): void {
  this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.selectedTenant = params['tenantId'];
      }
    );
    this.handleGetTenantResources()
  }

  
  async handleGetTenantResources() {
      this.resources$ = this.apiService.get_tenant_resources(this.selectedTenant);
      await this.resources$.subscribe((data: any) => {
        data.forEach((resource:any) => {
          let newResource = {
            resourceId: `${resource.PK}#${resource.SK}`,
            resource: resource._component_name,
            category: resource._resource_type,
            status: resource.status
          }
          this.RESOURCE_DATA.push(newResource);
        });
        this.dataSource.data = this.RESOURCE_DATA;
      })
  }

}
