import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TenantDetailComponent} from './tenant-detail/tenant-detail.component';
import {TenantBillingComponent} from './tenant-billing/tenant-billing.component';
import {TenantResourcesComponent} from './tenant-resources/tenant-resources.component';
import {TenantSolutionsComponent} from './tenant-solutions/tenant-solutions.component';
import {TenantUsageComponent} from './tenant-usage/tenant-usage.component';
import {TenantEntitlementsComponent} from './tenant-entitlements/tenant-entitlements.component';
import {TenantUsersComponent} from './tenant-users/tenant-users.component';
import {UsersComponent} from './users/users.component';
import {SkusComponent} from './skus/skus.component';
import {ResourcesComponent} from './resources/resources.component';
import {TenantsComponent} from './tenants/tenants.component';
import { SkusDetailComponent } from './skus/sku-detail/skus-detail.component';


const routes: Routes = [
  { path: '', redirectTo: '/tenants', pathMatch: 'full' },
  { path: 'tenants', component: TenantsComponent },
  { path: 'tenant-detail', component: TenantDetailComponent },
  { path: 'tenant-resources', component: TenantResourcesComponent },
  { path: 'tenant-billing', component: TenantBillingComponent },
  { path: 'tenant-solutions', component: TenantSolutionsComponent },
  { path: 'tenant-usage', component: TenantUsageComponent },
  { path: 'tenant-entitlements', component: TenantEntitlementsComponent },
  { path: 'tenant-users', component: TenantUsersComponent },
  { path: 'users', component: UsersComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'skus', component: SkusComponent },
  { path: 'skus/sku-detail/:id', component: SkusDetailComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
