<div class="inner-container">
  <div class="top-container">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Resource Name Column -->
        <ng-container matColumnDef="resource">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.resource}} </td>
        </ng-container>
  
        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.category}} </td>
        </ng-container>
  
        <!-- Details Column -->
        <!-- <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Details </th>
          <td mat-cell *matCellDef="let element"> {{element.details}} </td>
        </ng-container>
  -->
        <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="pie-card">
      <app-usage-card [entitlement]="this.usageData" />
    </div>
  </div>
  <div class="bottom-container">
    <div class="bar-graph">
      <mat-card class="card graph">
        <mat-card-title>
          <div class="graph-header">
            <div class="header-group">
              <img class="header-icon" src="../../assets/icon-usage-metrics.png" />
              <h2 style="margin-left: 20px;">Usage Metrics</h2>
            </div>
            <div class="select-group">
              <img class="select-icon" src="../../assets/icon-cal.png" />
              <mat-form-field appearance="outline" class="select">
                <mat-label class="input-label">Time Period</mat-label>
                <mat-select [value]="'12months'">
                  <mat-option value="12months">Last 12 Months</mat-option>
                  <mat-option value="one">First option</mat-option>
                  <mat-option value="two">Second option</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <app-bar-graph></app-bar-graph>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  
</div>