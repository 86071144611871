<mat-card (click)="handleExpand()" class="card" [ngClass]="{'expanded': isExpanded}">
  <mat-card-header>
    <mat-card-title>
      <div class="card-header">
        <p class="">{{this.solution.key}}</p>
        <ng-container *ngIf="this.errorCount > 0">
          <img class="status-icon" src="assets/danger-circle.png" height="35px" width="35px" />
        </ng-container>
        <ng-container *ngIf="this.errorCount === 0">
          <img class="status-icon" src="assets/tick-circle.png" height="35px" width="35px" />
        </ng-container>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="">
    <p class="status-content-txt">Status detail to go here. If it is really long it can break to two lines and keep on
      going.</p>
  </mat-card-content>
      <div class="footer-list">
        <div class="good footer">
          <p class="dot">&#x2022;</p>
          <p class="footer-txt">{{this.solution.value.length-this.down}}/{{this.solution.value.length}} Online</p>
        </div>
        <div class="warning footer">
          <p class="dot">&#x2022;</p>
          <p class="footer-txt">{{this.errorCount}} Issues</p>
        </div>
        <div class="bad footer">
          <p class="dot">&#x2022;</p>
          <p class="footer-txt">{{this.down}} Down</p>
        </div>
      </div>
      <mat-card-footer>
        <div class="issue-list-container">
          <p class="card-title-txt issue-header">Issues</p>
          <ul class="issue-list">
            <li class="issue-item" *ngFor="let error of this.errors">{{error.code}} - {{error.description}}</li>
          </ul>
        </div>
      </mat-card-footer>
</mat-card>
