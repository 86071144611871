import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../../api.service'
import {Observable} from 'rxjs';
import {Resource} from '../../resources/resources.component';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import {TenantsUsingResourceComponent} from '../../tenants-using-resource/tenants-using-resource.component';
import { SKU } from '../skus.component';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { Tenant } from 'src/app/tenants/tenants.component';
import { ActivatedRoute } from '@angular/router';

// export interface SKU{
//   skuId: String,
//   skuName: String,
//   entitlements: String,
//   price: String,
// }

//TODO: Change the type of tenant data from the API response to match the UI
// Need: price, and entitlements, and skuName if not Description
export interface SKUDetail{
  SKU: String,
  Enabled: boolean,
  Description: String,
  Version: String,
  Solutions:{
    Solution1: any ,
    Solution2: any ,
  }
 }

@Component({
  selector: 'skus-detail',
  standalone: true,
  templateUrl: './skus-detail.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, MatExpansionModule, TenantsUsingResourceComponent, MatSlideToggleModule, MatCardModule],
  styleUrls: ['./skus-detail.component.css']
})
export class SkusDetailComponent implements AfterViewInit, OnInit {
    sku:string = ""
    skus$!: Observable<Array<SKU>|any> 
    SKU_DATA: any[] = [];
    displayedColumns: string[] = ['SK', 'description', 'enabled', 'solutions'];
    dataSource = new MatTableDataSource(this.SKU_DATA);
    skuDataSource:any;
    skuId:any;
    skuDetail:any;     
    
    constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, public route:ActivatedRoute){
  }

  @ViewChild(MatSort) sort!: MatSort
  
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.skuId = params['id'];
    });

    this.handleGetSKUs(this.skuId);
    this.getSolutions(this.skuId);
  }

  async getSolutions(sku:string){
  //   this.apiService.getSolutionsBySku(sku).subscribe((data: any) => {
  //   this.dataSource = new MatTableDataSource(data);
  //   console.log('dataSource', this.dataSource)
  //  })
  }

  async handleGetSKUs(skuId: string) {
    this.apiService.get_skus_detail(skuId).subscribe((data: any) => {
      this.skuDataSource = new MatTableDataSource(data);
        console.log('skuDataSource', this.skuDataSource);
  })
}

changeStatus(event: MatSlideToggleChange, element: any) {
  // let inputObject: any = {}
  // inputObject.skuId=element.sku
  // inputObject.solution_id = element.solution_id
  // inputObject.enabled = event.checked
  // // console.log(inputObject)
  // this.apiService.addOrRemoveSolution(inputObject).subscribe((data: any) => {
   
  // });
}
}
