import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
@Component({
  selector: 'app-vertical-progress-bar',
  standalone: true,
  templateUrl: './vertical-progress-bar.component.html',
  imports: [MatProgressBarModule],
  styleUrls: ['./vertical-progress-bar.component.css']
})
export class VerticalProgressBarComponent {

  @Input() public percentage: number = 0;

  

  getBarColor(){
     if (this.percentage < 80){
      return 'accent'
    }else{
      return 'warn'
    }
  }

}
