import { Component, OnInit } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // standalone: true,
  // imports: [MatProgressBar]
})
export class AppComponent implements OnInit {
  title = 'dsio-internal-admin-client';
  public isLoggedIn = false;
  

  environment: any = environment;
  public firstName: String|undefined = '';
  constructor(private readonly keycloak: KeycloakService) {
  
  }
  
  public async ngOnInit() {
    this.isLoggedIn = this.keycloak.isLoggedIn();
    
    if (!this.isLoggedIn) {
      this.login()
      this.isLoggedIn = this.keycloak.isLoggedIn();
    } else {
      const userProfile: KeycloakProfile = await this.keycloak.loadUserProfile();
      this.firstName = userProfile.firstName;
    }      
  }

  public login() {
    this.keycloak.login();
  }

  public logout() {
    this.keycloak.logout();
  }
}
