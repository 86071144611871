import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import {CommonModule} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-tenant-entitlements',
  standalone: true,
  templateUrl: './tenant-entitlements.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, CommonModule],
  styleUrls: ['./tenant-entitlements.component.css']
})
export class TenantEntitlementsComponent implements AfterViewInit, OnInit {
  entitlements$!: Observable<any>

  public selectedTenant:any;



  public RESOURCE_DATA: any[] = [];
  displayedColumns: string[] = ['Entitlement Name', 'Type', 'Start Date', 'End Date'];
  dataSource = new MatTableDataSource(this.RESOURCE_DATA);
  public selectedRow:String = '1';
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, public route: ActivatedRoute){
  }

  @ViewChild(MatSort) sort!: MatSort


  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.selectedTenant = params['tenantId'];
      }
    );
    this.handleGetTenantEntitlements()
  }
  ngAfterViewInit(): void {
  this.dataSource.sort = this.sort;
  }

  setSelected(row: any):void {
    this.selectedRow = row.PK + '#' + row.SK; //was row.skuId
    // console.log(this.selectedRow);
  }

  addEntitlement():void {
    // console.log(` Add New Sku.`);
  }

  removeEntitlement(skuId:String):void {
    // console.log(` Remove Sku: ${skuId}`);
  }
  async handleGetTenantEntitlements() {
    this.entitlements$ = this.apiService.get_tenant_entitlements(this.selectedTenant);
    this.entitlements$.subscribe((entitlements: any) => {
      // this.entitlements = entitlements;
      console.log('entitlements: ', entitlements);
      entitlements.forEach((entitlement: any) => {
        let entitlementData = {
          //sku: entitlement.skuId,
          name: entitlement.SK,
          type: entitlement.record_type,
          startDate: entitlement.start_date,
          endDate: entitlement.end_date
        }
        this.RESOURCE_DATA.push(entitlementData);
      });
      this.dataSource.data = this.RESOURCE_DATA;
      // console.log(entitlements)
    });
  }
}
