import { DsioUiLibraryComponent } from './dsio-ui-library.component';
import { DsioUiLibraryService } from './dsio-ui-library.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'
import { PieGraphComponent } from "./pie-graph/pie-graph.component";
import { BarGraphComponent } from './bar-graph/bar-graph.component';
import { UsageCardComponent } from './usage-card/usage-card.component';
import { VerticalProgressBarComponent } from './vertical-progress-bar/vertical-progress-bar.component';
import { LineGraphComponent } from './line-graph/line-graph.component';
import { SystemCategoryCardComponent } from './system-category-card/system-category-card.component';
import { SystemMessagesCardComponent } from './system-messages-card/system-messages-card.component';
import { SystemSupportCardComponent } from './system-support-card/system-support-card.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TabComponent } from './tab/tab.component';
import { TabListComponent } from './tab-list/tab-list.component';


@NgModule({
    declarations: [],
    imports: [CommonModule, DsioUiLibraryComponent, PieGraphComponent, BarGraphComponent, UsageCardComponent, VerticalProgressBarComponent, LineGraphComponent, SystemCategoryCardComponent,
        SystemMessagesCardComponent, SystemSupportCardComponent, BreadcrumbComponent, TabComponent, TabListComponent,
    ],
    exports: [DsioUiLibraryComponent],
    providers: [DsioUiLibraryService]
})
export class DsioUiLibraryModule { }