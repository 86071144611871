import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import {User} from '../users/users.component';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-tenant-users',
  standalone: true,
  templateUrl: './tenant-users.component.html',
  imports:[MatButtonModule],
  styleUrls: ['./tenant-users.component.css']
})
export class TenantUsersComponent {
  users$!: Observable<Array<User>|any> 
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService){
  }


  async handleGetTenantUsers() {
      this.users$ = this.apiService.get_tenant_users();
      await this.users$.subscribe({
        next(response) {
          response.forEach((user:User) => {
            alert(JSON.stringify(user));
          });
        
        },
        error(err) {
          console.log(err)
        },
      })
  }

}
