import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import {Resource} from '../resources/resources.component';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import {TenantsUsingResourceComponent} from '../tenants-using-resource/tenants-using-resource.component';
import { Router, RouterModule } from '@angular/router';


// export interface SKU{
//   skuId: String,
//   skuName: String,
//   entitlements: String,
//   price: String,
// }

//TODO: Change the type of tenant data from the API response to match the UI
// Need: price, and entitlements, and skuName if not Description
export interface SKU{
  SKU: String,
  Enabled: boolean,
  Description: String,
  Version: String,
  Solutions:{
    Solution1: any ,
    Solution2: any ,
  }
 }

@Component({
  selector: 'app-skus',
  standalone: true,
  templateUrl: './skus.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, MatExpansionModule, TenantsUsingResourceComponent, RouterModule],
  styleUrls: ['./skus.component.css']
})
export class SkusComponent implements AfterViewInit, OnInit {
 tenants =  new MatTableDataSource([
    {tenantId: '1', companyName: 'rrrrrrr', primaryContact: 'rrrrrr', status: 'Online'},
    {tenantId: '2', companyName: 'Company Name', primaryContact: 'Contact Name', status: 'Online'},
    {tenantId: '3', companyName: 'Company Name', primaryContact: 'Contact Name', status: 'Online'},
    {tenantId: '4', companyName: 'Company Name', primaryContact: 'Contact Name', status: 'Online'},
    {tenantId: '5', companyName: 'Company Name', primaryContact: 'Contact Name', status: 'Online'},
 

  ]);
  step:Boolean = false
  sku:string = ""
  skus$!: Observable<Array<SKU>|any> 
  SKU_DATA: any[] = [];
  displayedColumns: string[] = ['SK', 'description', 'enabled',];
  dataSource = new MatTableDataSource(this.SKU_DATA);
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, public router: Router){
  }

  @ViewChild(MatSort) sort!: MatSort
  @Output() public skuID: string = "";

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.handleGetSKUs();
  }

  loadTenants(skuId: any){
    //this.sku=row.PK
    //this.skuID = row.PK;
    //this.router.navigate(['/sku-detail']);
    // console.log(row)
    // this.step=false
    // this.step=true
    

  }

  navigateToDetails(skuId:string) {
    this.router.navigate([`skus/sku-detail/${skuId}`]);
  }

  async handleGetSKUs() {
      this.skus$ = this.apiService.get_skus();
      await this.skus$.subscribe((data: any) => {
        data.forEach(async (sku: any, index: number) => {
          // console.log({sku})
          
          this.SKU_DATA.push(sku);
          //console.log(this.SKU_DATA)
        })
      this.dataSource.data = this.SKU_DATA;
  })
  }
}
