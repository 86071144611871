<div class="inner-container">
  <div>
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Sku Name Column -->
        <ng-container matColumnDef="SK">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU Name </th>
          <td mat-cell *matCellDef="let element"> {{element.SK}} </td>
        </ng-container>
  
        <!-- Category Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>
  
        <!-- Details Column -->
        <ng-container matColumnDef="enabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </th>
          <td mat-cell *matCellDef="let element"> {{element.enabled}} </td>
        </ng-container>
  
        <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row.SK)"></tr>
      </table>
  </div>