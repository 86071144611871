import {CommonModule} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';

@Component({
  selector: 'app-system-category-card',
  standalone: true,
  templateUrl: './system-category-card.component.html',
  imports: [MatCardModule, MatExpansionModule, CommonModule],
  styleUrls: ['./system-category-card.component.css']
})
export class SystemCategoryCardComponent implements OnInit{

  @Input() solution: any;
  errorCount:any = 0;
  errors:any = 0;  
  down:any = 0;
  public isExpanded:boolean = false;
  issue: any;
  footer: any;

  constructor() { 
  }

ngOnInit(): void {
  this.calculateErrors()
  this.calculateDown()
  
}
  handleExpand() {
    this.isExpanded = !this.isExpanded;
  }
  calculateErrors(){
    
    for(let component of this.solution.value){
      if(component.errors){
        this.errors = component.errors
        this.errorCount+=component.errors.length;
      }
    }
  }

  

  calculateDown(){
    
    for(let component of this.solution.value){
      if(component.status!=='available' && component.status){
        this.down++;
      }
    }
  }
  
}
