import { Component, Input, OnInit } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';

@Component({
  selector: 'app-pie-graph',
  standalone: true,
  templateUrl: './pie-graph.component.html',
  imports: [
    NgCircleProgressModule,
  ],
  styleUrls: ['./pie-graph.component.css']
})
export class PieGraphComponent implements OnInit{

  @Input() public top3Usage: any = 0;
  @Input() public percentage: number = 0;
  @Input() public title: string = '';
  @Input() public subtitle: string = '';
  @Input() public fontSize: string = '';
  @Input() public subtitleFontSize: string = '';

  @Input() public radius: number = 66;
  @Input() public showSubtitle: boolean = false;

  @Input() public barColor: string = '';
  @Input() public entitlement: any = 0;
  
  constructor() {}

  ngOnInit() {
    // console.log("top3Usage: ")
    //   console.log(this.top3Usage)
    if(this.top3Usage.usage.hasOwnProperty('capacity')) {
      this.percentage = (this.top3Usage.usage.consumption / this.top3Usage.usage.capacity) * 100;
    } else {
      this.percentage = (this.top3Usage.usage.count / this.top3Usage.usage.max_count) * 100;
    }
    this.barColor = this.getBackgroundColor();
    
  }
  public getBackgroundColor(): string {
    // console.log(this.percentage as number)
    if (this.percentage < 50) {
      return '#A0CC44';
    } else if (this.percentage < 76) {
      return '#D6CF2B';
    } else {
      return '#CC8D44';
    }
  }
};
