import { Component, ViewChild, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {MatSortModule, MatSort} from '@angular/material/sort';
import { UsageCardComponent } from 'dsio-ui-library';
import { ActivatedRoute } from '@angular/router';
import {CommonModule} from '@angular/common';


@Component({
  selector: 'app-tenant-usage',
  standalone: true,
  templateUrl: './tenant-usage.component.html',
  imports: [MatButtonModule, MatSortModule, UsageCardComponent, CommonModule],
  styleUrls: ['./tenant-usage.component.css']
})

export class TenantUsageComponent implements OnInit {
  entitlements$!: Observable<any> 

  @Output() public entitlements: any = []

  public selectedTenant:any;
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService,public route: ActivatedRoute){
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.selectedTenant = params['tenantId'];
      }
    );
    this.getEntitlements()
  }
  

  async getEntitlements() {
    this.entitlements$ = this.apiService.get_tenant_entitlements(this.selectedTenant);
    this.entitlements$.subscribe((entitlements: any) => {
      this.entitlements = entitlements;
      // console.log(this.entitlements)
    });
}

}