import { Component, NgModule, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
  PRIMARY_OUTLET,
  RouterLink,
  RouterModule
} from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter, distinctUntilChanged, map, subscribeOn } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  standalone: true,
  styleUrls: ['./breadcrumb.component.css'],
  imports: [CommonModule, RouterModule]
})
// @NgModule({
//     declarations: [BreadcrumbComponent],
//     exports: [BreadcrumbComponent],
// })
export class BreadcrumbComponent implements OnInit {
  public breadcrumbs: Breadcrumb[] | undefined;

  /**
  /*.filter(event => event instanceof NavigationEnd)
 .distinctUntilChanged()
 .map(event =>  this.buildBreadCrumb(this.activatedRoute.root)); */

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    let breadcrumb: Breadcrumb = {
      label: 'Home',
      url: '/gateway/home/',
    };

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        //set breadcrumbs
        let root: ActivatedRoute = this.route.root;
        this.breadcrumbs = this.getBreadcrumbs(root);
        this.breadcrumbs = [breadcrumb, ...this.breadcrumbs];
      });
  }

  private getBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = []
  ): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'title';
    //debugger;
    //get the child routes
    let children: ActivatedRoute[] = route.children;
    console.log("breadcrumb route: " + route);
    console.log("breadcrumb route: children " + route.children);

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length == 0) {
        continue;
      }

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');

      //append route URL to URL
      url += `/${routeURL}`;

      //add breadcrumb
      let breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        url: url,
      };
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    //console.log("breadcrumbs: " + breadcrumbs)
    return breadcrumbs;
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
}
