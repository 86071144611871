import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from '../api.service'
import { Observable, interval } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, ActivatedRoute } from '@angular/router';
export interface Solution {
  solutionId: String,
  name: String,
  action: String | Boolean
  status: String
}

export interface Task {
  taskId: String,
  action: String,
  status: String
}


@Component({
  selector: 'app-tenant-solutions',
  standalone: true,
  imports: [MatButtonModule, MatTableModule, MatSortModule, CommonModule, MatSlideToggleModule],
  templateUrl: './tenant-solutions.component.html',
  styleUrls: ['./tenant-solutions.component.css']
})
export class TenantSolutionsComponent implements OnInit, AfterViewInit {
  solutions$!: Observable<Array<Solution> | any>
  task$!: Observable<Task | any>
  data: Solution[] = []
  public selectedTenant: any;
  displayedColumns: string[] = ['name', 'status', 'action'];
  refreshSubscription: any;

  dataSource = new MatTableDataSource(this.data);


  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, public route: ActivatedRoute) {
  }

  @ViewChild(MatSort) sort!: MatSort
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.selectedTenant = params['tenantId'];
      }
      );
    this.handleGetSolutions()
  }

  toggleSolution(event: MatSlideToggleChange, solution: Solution) {
    console.log(event.checked)
    console.log(solution)
    if (event.checked) {
      event.source.disabled = true;
      solution.action = "enable"
      this.apiService.patch_tenant_solution_task(
        this.selectedTenant,
        solution
      ).subscribe((data: any) => {
        if (data.status == "enabling") {
          solution.status = "Enabling";
          this.refreshSubscription = interval(1000).subscribe(() => { this.handleGetSolutions();
            if (solution.status == "Enabled") {
              this.refreshSubscription.unsubscribe();
            }
          });
        } else {
          event.source.disabled = false;
        }
      })
    }
  }

  isSolutionToggleDisabled(solution: Solution) {
    if (solution.status == "Available") {
      return false
    }
    return true
  }

  async handleGetSolutions() {
    this.solutions$ = this.apiService.get_tenant_solutions(this.selectedTenant);
    let that = this;
    

    this.solutions$.subscribe({
      next(response) {
        that.data = []
        response.forEach((solution: Solution) => {
          that.data.push(solution)

        });

        that.dataSource.data = that.data;
        //console.log('solutions data');
        //console.log( that.dataSource.data);
      },
      error(err) {
        console.log(err)
      },
    })
  }
}


