import { Component } from '@angular/core';

@Component({
  selector: 'lib-dsio-ui-library',
  standalone: true,
  imports: [],
  template: `
    <p>
      dsio-ui-library works!
    </p>
  `,
  styles: ``
})
export class DsioUiLibraryComponent {

}
