<mat-card class="card-min-height">
  <mat-card-header>
  <mat-card-title>
    <div class="card-header p-0">
        <img class="d-ifx mr-10" src="assets/icon-support.png" height="35px" width="35px" alt="System Status">
        <p class="d-ifx">Support</p>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p class="status-content-txt">Reach out to support about system issues.</p>
    <div class="footer">
      <button mat-flat-button color="primary" class="primary-btn">Contact Support</button>
    </div>
  </mat-card-content>
  </mat-card>