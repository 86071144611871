<div class="inner-container">
  <div class="btn-container">
    <button (click)="navigateToDetails(selectedRow)" mat-flat-button color="primary" class="primary-btn mb-15">
      View Tenant
    </button>
  </div>
  <mat-progress-bar *ngIf="isloading" mode="indeterminate" value="80">
  </mat-progress-bar>
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- TenantId Column -->
    <ng-container matColumnDef="tenantId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.tenantId}} </td>
    </ng-container>

    <!-- Company Name Column -->
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
      <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
    </ng-container>

    <!--         
        <ng-container matColumnDef="primaryContact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
          <td mat-cell *matCellDef="let element"> {{element.primaryContact}} </td>
        </ng-container>
  
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element">
            <p [ngClass]="{online: element.status === 'Online', offline: element.status === 'Offline'}">
              {{element.status}}
            </p>
          </td>
        </ng-container>
      -->
    <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setSelected(row)"
      (dblclick)="navigateToDetails(selectedRow)" [ngClass]="{selecetedRow: selectedRow===row.tenantId}">
    </tr>
  </table>
</div>