import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

@Component({
  selector: 'app-line-graph',
  standalone: true,
  templateUrl: './line-graph.component.html',
  imports: [
    NgChartsModule,
  ],
  styleUrls: ['./line-graph.component.css']
})
export class LineGraphComponent implements OnInit {

 
  @Input() public values: any;

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        data: [],
        label: 'usage',
        fill: false,
        tension: 0.5,
        borderColor: '#A0CC44',
      },
    ],
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
    elements: {
      point: {
        radius: 0,
      },
    },
  };
  public lineChartLegend = false;


constructor() {
}

ngOnInit(): void {
  this.lineChartData.datasets[0].data = this.values;
}

}
