import { Component } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-system-support-card',
  standalone: true,
  templateUrl: './system-support-card.component.html',
  imports: [MatCardModule, MatButtonModule],
  styleUrls: ['./system-support-card.component.css']
})
export class SystemSupportCardComponent {

}
