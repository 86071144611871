import { Component, Input,Output, OnInit } from '@angular/core';
import {ActivatedRoute, RouterModule} from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import { KeycloakService } from 'keycloak-angular';
import {Observable} from 'rxjs';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import {TenantResourcesComponent} from '../tenant-resources/tenant-resources.component';
import {TenantSolutionsComponent} from '../tenant-solutions/tenant-solutions.component';
import {TenantUsageComponent} from '../tenant-usage/tenant-usage.component';
import {TenantBillingComponent} from '../tenant-billing/tenant-billing.component';
import {TenantEntitlementsComponent} from '../tenant-entitlements/tenant-entitlements.component';
import { TabListComponent } from 'dsio-ui-library';
import {TabComponent} from 'dsio-ui-library';


// export interface Tenant{
//   tenantId: String,
//   companyName: String,
//   primaryContats: {
//     userId: String,
//     name: String,
//   },
//   healthIndicator: String
// }
export interface Tenant{
  tenantId: String,
  companyName: String,
  primaryContact: String,
  status: String,
  action: any,
}


@Component({
  selector: 'app-tenant-detail',
  standalone: true,
  templateUrl: './tenant-detail.component.html',
  imports:[MatButtonModule, MatTabsModule, MatCardModule, TenantResourcesComponent, TenantSolutionsComponent, TenantUsageComponent, TenantBillingComponent, TenantEntitlementsComponent, RouterModule,TabListComponent, TabComponent ],
  styleUrls: ['./tenant-detail.component.css']
})
export class TenantDetailComponent{

  entitlements$!: Observable<any> 
  @Input() public tenantId!: String;
  public selectedTenant:any;

  @Output() public entitlements: any;  
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService, public route: ActivatedRoute){
  }

  


}
