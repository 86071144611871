<mat-card class="card-min-height">
  <mat-card-header>
  <mat-card-title>
    <div class="card-header p-0">
        <img class="d-ifx mr-10" src="assets/status-message-icon.png" height="35px" width="35px" alt="System Status">
        <p class="d-ifx">Messages</p>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p class="status-content-txt">There are currently no new messages to display.</p>
  </mat-card-content>
</mat-card>