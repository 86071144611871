<div class="inner-container">
  <div>
      <h4>SKU Detail</h4>
      <table mat-table [dataSource]="skuDataSource" matSort>
        <!-- Sku Name Column -->
        <ng-container matColumnDef="SK">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU Name </th>
          <td mat-cell *matCellDef="let element"> {{element.SK}} </td>
        </ng-container>
  
        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>
  
        <!-- Enabled Column -->
        <ng-container matColumnDef="enabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enabled </th>
          <td mat-cell *matCellDef="let element"> 
            <!-- {{element.enabled}}  -->
          <!-- <mat-slide-toggle class="mat-accent" (change)="changeStatus($event,element)" [checked]=" element.assigned" [labelPosition]="'after'"
          [hideIcon]="false"> -->
          <mat-slide-toggle class="mat-accent" (change)="changeStatus($event,element)" [checked]="element.enabled === true" [labelPosition]="'after'" [hideIcon]="true">
          <p class='label'>Enabled</p>
        </mat-slide-toggle></td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="solutions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Associated Solutions </th>
          <td mat-cell *matCellDef="let element"> {{element.solutions }} </td>
        </ng-container>
  
        <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
  </div>