import { Component } from '@angular/core';
import {MatCardModule} from '@angular/material/card';



@Component({
  selector: 'app-system-messages-card',
  standalone: true,
  templateUrl: './system-messages-card.component.html',
  imports: [MatCardModule],
  styleUrls: ['./system-messages-card.component.css']
})
export class SystemMessagesCardComponent {

}
