import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {ApiService} from '../api.service'
import {Observable} from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSortModule, MatSort} from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import {TenantsUsingResourceComponent} from '../tenants-using-resource/tenants-using-resource.component';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { environment } from 'src/environments/environment';



// export interface Resource{
//   category: String,
//   component: {
//     resourceName: String,
//     resourceArn: String,
//     tenantOwnership: String
//   }
// }

//TODO: Change the type of tenant data from the API response to match the UI
// Need: Status, Component, Category id not in the Detail

export interface Resource {
  Id: String,
  Type: String,
  Detail: {
      ARN: String,
      Type: String
  }
}


@Component({
  selector: 'app-resources',
  standalone: true,
  templateUrl: './resources.component.html',
  imports: [MatButtonModule, MatTableModule, MatSortModule, MatExpansionModule, TenantsUsingResourceComponent, MatIconModule, MatInputModule, MatFormFieldModule, MatSelectModule],
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements AfterViewInit, OnInit {
  resources$!: Observable<Array<Resource>|any> 

  RESOURCE_DATA: any[] = [];
  displayedColumns: string[] = ['_ref_id', 'reference_type', '_component_name'];
  dataSource = new MatTableDataSource(this.RESOURCE_DATA);
  constructor(private apiService: ApiService, private readonly keycloak: KeycloakService){
  }

  @ViewChild(MatSort) sort!: MatSort
  ngAfterViewInit(): void {
  this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.handleGetResources();
  }

  async handleGetResources() {
      this.resources$ = this.apiService.get_resources();
      await this.resources$.subscribe((data: any) => {
      this.dataSource.data = data;
  })
  }
}
