import {
  Component,
  ContentChildren,
  QueryList,
  AfterContentInit,
} from '@angular/core';
import {TabComponent,} from '../tab/tab.component';
import {CommonModule} from '@angular/common';
// import { DynamicTabsDirective } from './dynamic-tabs.directive';

@Component({
  selector: 'dsio-tab-list',
  standalone: true,
  templateUrl: './tab-list.component.html',
  styleUrls: ['./tab-list.component.css'],
  imports: [TabComponent, CommonModule]
})
export class TabListComponent implements AfterContentInit{
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent> = new QueryList();

  // contentChildren are set
  ngAfterContentInit() {
    // get all active tabs
    let activeTabs = this.tabs.filter((tab) => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: TabComponent) {
    // deactivate all tabs
    this.tabs.toArray().forEach((tab) => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;
  }
}
