<div class="inner-container">
    <div class="btn-group">
      <button mat-button color="primary" (click)="handleEditUser(this.selectedRow)"
        style=" border-radius: 25px; width:90px;background-color:transparent; margin-right:25px;margin-bottom: 30px;">
        <span style="color: #53a7fa">Edit User</span>
      </button>
      <button mat-raised-button (click)="handleAddNewUser()" color="primary"
        style="border-radius: 25px; width:95px;background-color:#53A7FA; margin-bottom: 30px; align-self: flex-end;">
        New User</button>
    </div>
           <mat-progress-bar *ngIf="isloading" 
      mode="indeterminate" value="80"/>
      <table mat-table [dataSource]="dataSource" matSort>
        
        <!-- Username Column -->
        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
          <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
        </ng-container>
  
        <!-- Email Column -->
        <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
        </ng-container>
  
        <!-- Name Column -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.firstName}} {{element.lastName}} </td>
        </ng-container>
  
        <!-- Role Column -->
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
          <td mat-cell *matCellDef="let element"> {{element.role}} </td>
        </ng-container>
  
        <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setSelected(row)"
          [ngClass]="{selecetedRow: selectedRow===row.userId}"></tr>
      </table>
</div>