<h2 mat-dialog-title>Add a User</h2>
<mat-dialog-content>
  <div class="modal-inputs">
    <mat-form-field>
      <mat-label>User Name</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>E-mail Address</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Role</mat-label>
      <mat-select>
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="Role 1">Role 1</mat-option>
        <mat-option value="Role 2">"Role 2"</mat-option>
        <mat-option value="Role 3">"Role 3"</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="modal-btn-group">
    <button mat-button color="primary"
      style=" border-radius: 25px; width:90px;background-color:transparent;margin-bottom:30px;">
      <span style=" color: #53a7fa">Cancel</span>
    </button>
    <button mat-raised-button mat-dialog-close cdkFocusInitial color="primary"
      style="border-radius: 25px; width:95px;background-color:#53A7FA; margin-bottom: 30px; margin-right:20px;">Add
      User</button>
  </div>
</mat-dialog-actions>