import { Component,OnInit, ViewChild, AfterViewInit, Output, Input, DoCheck, OnChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CategoryScale, ChartConfiguration, ChartData, ChartDataset } from 'chart.js';
import { NgChartsModule, BaseChartDirective } from 'ng2-charts';


@Component({
  selector: 'app-bar-graph',
  standalone: true,
  templateUrl: './bar-graph.component.html',
  imports: [
    NgChartsModule,
  ],
  styleUrls: ['./bar-graph.component.css']
})
export class BarGraphComponent implements OnInit, AfterViewInit, OnChanges
  {
    @ViewChild(BaseChartDirective) chart: BaseChartDirective<'bar'> | undefined;

    @Input() ytdUsage: any;
    @Input() top3Usage: any;
    @Input() type: any;

    entitlements$!: Observable<Array<any>|any>
    @Output() entitlements:any

    public oldYtdUsage: any = [];

    public barChartLegend = true;
    public barChartPlugins = [];

    public dataSet: any;
    public skuName: string = '';

    public tenantId: string = sessionStorage.getItem("tenantId") as string;
    public token = sessionStorage.getItem("token") as string;

    public chartColors: Array<any> = ['#D6CF2B', '#4744CC', '#1976D2', '#A0CC44'];

    public barChartData: ChartConfiguration<'bar'>['data'] = {
      labels: [ 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG','SEP','OCT','NOV','DEC' ],
      datasets: [
      ]
    };
    
    public barChartOptions: ChartConfiguration<'bar'>['options'] = {
  
        plugins: {
          legend: {
            labels: {
              boxWidth: 10,
              boxHeight: 10,
              useBorderRadius: true,
              borderRadius: 5,
            }
          }
        },
        layout:{
          autoPadding: true,
        },
        animation: {
          easing: 'easeOutQuint',
          duration: 1000
        },
        responsive: true, 
        maintainAspectRatio: true,
        scales: {
          xAxes: {
            stacked: false,
              grid: {
                  display: false,
              }
          },
          yAxes: {
            stacked: false,
          }
      }

    }

    ngOnInit() {
      this.getYearlyUsage();
  }

    ngAfterViewInit(): void {
    }

    ngOnChanges() {
      this.getYearlyUsage();
    }
    
    getYearlyUsage() {
      if(this.ytdUsage) {
        this.dataSet = this.ytdUsage;
        const listOfSolutions = this.seperateBySolutions(this.dataSet)
        this.createDataArray(this.dataSet, listOfSolutions)
    }
  }



    seperateBySolutions(solutions: any) {
      const solutionArray: any[] = [];
      solutions.forEach((element: any) => {
        let solution = `${element.solution_name}#${element.dimension_name}`;
        if (!solutionArray.includes(solution)&& element.usage.hasOwnProperty(this.type)) {
          solutionArray.push(solution);
        }

      });
      return solutionArray;
    }

    createDataArray(data: any, listOfSolutions: any){
      const dataObject:any = {}
      console.log(listOfSolutions);
      listOfSolutions.forEach((solution: any) => {
        const dataRecord:Array<number> = [0,0,0,0,0,0,0,0,0,0,0,0]
        this.dataSet.forEach((element: any) => {
          let sku = `${element.solution_name}#${element.dimension_name}`;
          if(sku === solution){

            dataObject[solution] = {data: dataRecord, label: `${element.solution_name} - ${element.dimension_name}`, xAxisID:'xAxes', barThickness: 'flex',  yAxisID:'yAxes', barPercentage: 0.5, borderRadius: 40 }

            if(element.usage.hasOwnProperty('consumption')) {
              if (element.month ==="January"){
                dataObject[solution].data[0] = element.usage.consumption
              }
              if (element.month ==="February"){
                dataObject[solution].data[1] = element.usage.consumption
              }
              if (element.month ==="March"){
                dataObject[solution].data[2] = element.usage.consumption
              }
              if (element.month ==="April"){
                dataObject[solution].data[3] = element.usage.consumption
              }
              if (element.month ==="May"){
                dataObject[solution].data[4] = element.usage.consumption
              }
              if (element.month ==="June"){
                dataObject[solution].data[5] = element.usage.consumption
              }
              if (element.month ==="July"){
                dataObject[solution].data[6] = element.usage.consumption
              }
              if (element.month ==="August"){
                dataObject[solution].data[7] = element.usage.consumption
              }
              if (element.month ==="September"){
                dataObject[solution].data[8] = element.usage.consumption
              }
              if (element.month ==="October"){
                dataObject[solution].data[9] = element.usage.consumption
              }
              if (element.month ==="November"){
                dataObject[solution].data[10] = element.usage.consumption
              }
              if (element.month ==="December"){
                dataObject[solution].data[11] = element.usage.consumption
              }
          }else if(element.usage.hasOwnProperty('count')) {
                if (element.month ==="January"){
                  dataRecord[0] = element.usage.count
                }
                if (element.month ==="February"){
                  dataRecord[1] = element.usage.count
                }
                if (element.month ==="March"){
                  dataRecord[2] = element.usage.count
                }
                if (element.month ==="April"){
                  dataRecord[3] = element.usage.count
                }
                if (element.month ==="May"){
                  dataRecord[4] = element.usage.count
                }
                if (element.month ==="June"){
                  dataRecord[5] = element.usage.count
                }
                if (element.month ==="July"){
                  dataRecord[6] = element.usage.count
                }
                if (element.month ==="August"){
                  dataRecord[7] = element.usage.count
                }
                if (element.month ==="September"){
                  dataRecord[8] = element.usage.count
                }
                if (element.month ==="October"){
                  dataRecord[9] = element.usage.count
                }
                if (element.month ==="November"){
                  dataRecord[10] = element.usage.count
                }
                if (element.month ==="December"){
                  dataRecord[11] = element.usage.count
                }
              }
            
        }
      })
      this.barChartData.datasets.push(dataObject[solution])
      let colorIndex = (this.barChartData.datasets.length - 1);
      this.barChartData.datasets[colorIndex].backgroundColor = this.chartColors[colorIndex];
    })
    } 
  }