<div class="inner-container">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Solution Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.action!=='request'">
            <mat-slide-toggle class="mat-accent" [checked]=" element.action" [labelPosition]="'after'" (change)="toggleSolution($event, element)"
              [hideIcon]="true" [disabled]="isSolutionToggleDisabled(element)">
              <p class='label'>Enabled</p>
            </mat-slide-toggle>
          </ng-container>
          <ng-container *ngIf="element.action==='request'">
            <button mat-raised-button color="primary" style="border-radius: 25px; width:92px;background-color:#53A7FA">
              <p class="btnText">Request</p>
            </button>
          </ng-container>
      </ng-container>



      <tr class="header-shadow" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
